import React from 'react';

export default React.forwardRef((props, ref) => {
    let { allowDecimal, min, outerClassName: outerClassNameProp, className, button, errored, style, ...rest } = props;
    let outerClassName = (props.outerClassName) ? props.outerClassName : '';
    let innerClassName = `flex-item width-100 ${(props.className) ? props.className : ''}`;
    errored = props?.errored ? props.errored : { messageText: '', option: 0 };
    allowDecimal = props.allowDecimal ?? true;
    const minValue = props.min ?? 0;

    if (props.button) outerClassName += ' withButton ';

    const handleKeyDown = (e) => {
        const { key } = e;
        const isNumericKey = key >= '0' && key <= '9';
        const isControlKey = key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight';
        const isAllowedDecimal = allowDecimal && key === '.';

        if (!isNumericKey && !isControlKey && !isAllowedDecimal) {
            e.preventDefault();
        }
    };

    const handleInput = (e) => {
        let value = e.target.value;

        if (allowDecimal) {
            value = value.replace(/[^0-9.]/g, '');
            const decimalCount = (value.match(/\./g) || []).length;
            if (decimalCount > 1) {
                value = value.replace(/(\.+)$/, '');
            }
        } else {
            value = value.replace(/[^0-9]/g, '');
        }

        if (minValue > 0 && value.length === 1 && value === '0') {
            value = '';
        }

        props.value = value;
    };

    const handleChange = (e) => {
        const value = e.target.value;

        const isValidInput = allowDecimal
            ? /^[0-9.]*$/.test(value)
            : /^[0-9]*$/.test(value);

        if (isValidInput && props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className={outerClassName}>
            <input
                {...rest}
                type="text"
                onWheel={(ev) => ev.currentTarget.blur()}
                ref={ref}
                disabled={props.disabled}
                className={innerClassName + (errored.error && errored.option === 0 && !props.disabled ? ' errored border ' : '')}
                value={props.value}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                onChange={handleChange}
                style={(style) ? style : {}} 
            />
            {errored.error && errored.option === 1 && <div className="errored message">{errored.messageText}</div>}
            {props.button && <button onClick={props.onClick}>{props.button}</button>}
        </div>
    );
});